
body {
  margin: 0;
  font-size: medium;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(40, 40, 41);
  color:#F6F6F6;
}

input {
  font-size: medium;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  border: 1px solid rgb(65, 65, 65);
}

button {
  border: 1px solid rgb(60, 60, 60);
  background-color: rgb(32, 32, 32);
  color: #F6F6F6;
  transition: background-color 0.1s;
}

button:hover {
  background-color: rgb(123, 130, 133);
}

input[type='radio']+label {
  border: 1px solid rgb(60, 60, 60);
  background-color: rgb(42, 42, 43);
  color: #F6F6F6;
  font-weight: bold;
}

input[type='radio']:checked+label {
  background-color: rgb(80, 160, 222);
}

input[type='radio']:hover+label{
  background-color: rgb(123, 130, 133);
  transition: all 0.05s;
}

[data-tooltip]:before {            
  position : absolute;
   content : attr(data-tooltip);
   opacity : 0;

   font-size: small;
   transition: all 1s step-end;
   padding: 5px;
   color: #333;
   border-radius: 1px;
}

[data-tooltip]:hover:before {        
  opacity : 1;
  background-color: rgb(223, 223, 223);
  margin-top: -20px;
  margin-left: 20px;   
}

[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}