
input[type=text] {
    padding: 4px 4px;
    margin: 1px 1px;
    box-sizing: border-box;
    border: 0px solid rgb(39, 39, 39);
    border-radius: 3px;
    background-color: rgb(47, 47, 47);
    font-size: medium;
    color: rgb(240, 240, 240);
    font-weight: bold;
}

input[type=text]::placeholder {
    color: rgb(99, 99, 99);
}

input[type=text]:disabled {
    -webkit-text-fill-color: rgb(174, 174, 174);
    background-color: rgb(74, 74, 74);
    color: rgb(174, 174, 174);
    opacity: 1;
}

input[type=text]:focus {
    outline-width: 1px;
}

.item-container {
    display: flex;
    margin: 0px 10px;
    justify-content: center;
}
/* 
.item-container input[type=text] {
    text-transform: uppercase;
} */

.item-ticker {
    text-indent: 2px;
    width: 70px;
}

.not-valid-ticker {
    color: rgb(255, 105, 85) !important;
}

.item-hash {
    text-align: center;
    font-weight: bold;
    width: 30px;
}

.item-count {
    text-indent: 2px;
    width: 50px;
}

.item-weight {
    width: 50px;
    text-indent: 2px;
}