.abt-content {
    margin: 40px 9vw;
}

.abt-text p {
    font-size: 13px;
    color: rgb(187, 187, 187);
}

.abt-text h5 {
    margin-top: 50px;
    margin-bottom: 0px
}

.abt-text h3 {
    margin-top: 50px;
}

.abt-small {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 12px;
}

.abt-dark i {
    color: rgb(106, 106, 106);
}