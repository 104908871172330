

.save-icon {
    margin: -7px -18px;
    padding-top: 4px 0px;
    fill: #F6F6F6;
}

.side-buttons {
    margin-top: 5px;
    display: flex;
    justify-content: center;
}

.side-btn {
    border-radius: 5px;
    font-weight: bold;
    font-size: medium;
    padding: 2px 15px;
    margin: 5px;
}

.side-total-label {
    padding: 0px;
    margin: 10px 0px 0px 0px;
}

.side-total-value {
    padding: 0px;
    margin: 0px 0px 10px 0px;
}

.side-save-btn {
    border-radius: 5px;
    font-weight: bold;
    font-size: medium;
    padding: 2px 12px;
    margin: 5px;
}

.side-apply-row {
    display: flex;
    justify-content: center;
    height: 40px;
}

.side-apply-row button {
    margin: 0px 0px 0px 78px;
    height: 26px;
}

.side-apply-row h4 {
    width: 50px;
    max-width: 50px;
    margin: 1px 0px 0px 20px;
    transition: color 0.5s;
}

.side-hidden-text {
    color: transparent !important;
}

@media (min-width: 800px) {

    .side-btn:hover {
        background-color: rgb(123, 130, 133);
    }

    .side-save-btn:hover {
        background-color: rgb(123, 130, 133);
    }

}