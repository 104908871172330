
.app {
  text-align: center;
  vertical-align: middle;
}

.nav-bar {
  position: fixed;
  top: 0px;
  z-index: 2;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
}

.nav-side {
  z-index: 1;
  position: fixed;
  padding-top: 40px;
  left: 0px;
  top: 0px;
  overflow-y: scroll;
  background-color: #1E1E1E;
  max-height: 100vh;
  height: 100%;
  width: 250px;
}

.nav-content {
  padding-top: 40px;
  padding-left: 240px;
  text-align: center;
  align-items: center;
}

.nav-footer {
  padding-left: 240px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #313131;
  font-size: 12px;
  color: rgb(124, 124, 124);
}

.nav-footer-text {
  margin: 20px;
}

.content {
  margin: 0px 6vw 20px 6vw;
  align-items: center;
}

.optimize-text {
  margin: 15px 0px 15px 0px;
}

.optimize-text p {
  font-size: 13px;
  color: rgb(182, 182, 182);
  margin: 0px;
  padding: 0px;
}

.optimize-text h2 {
  margin: 4px;
  font-size: 13px;
  color: rgb(182, 182, 182);
  font-weight: bold;
}

.optimize-text h3 {
  font-size: 13px;
  margin: 2px;
  color: rgb(182, 182, 182);
  font-weight: bold;
}


.screen-table {
  margin: 0 auto;
  border-spacing: 0;
}

.highlight  {
  background-color: #2d4f62;
}

th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.screen-table th {
  font-weight: bold;
}

.screen-table th {
  background: rgb(46, 46, 46);
  border-bottom: 1px solid #dadada;
  font-weight: bold;
  align-content: stretch;
  text-align: center;
  font-size: min(13px, max(9px, 1.0vw));
}

.screen-table td {
  border-top: 1px solid #ddd;
  padding: 3px calc(1vw - 4px);
  font-size: min(13px, max(9px, 1.0vw));
}

.screen-table button {
  font-weight: bold;
  min-height: 27px;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 4px max(1px, calc(1vw - 4px));
  background-color: transparent;
  border: none;
  color: #dadada;
  cursor:pointer;
}

.screen-table tbody tr:first-child td {
  border-top: none;
}

.screen-head {
  transition: background-color 1s;
  font-size: min(13px, max(10px, 1.0vw));
}

@media (min-width: 800px) {
  .screen-table button:hover {
    background-color: #777777;
  }
}

@media (max-width: 800px) {
  .nav-side {
    position: relative;
    overflow-y: none;
    max-height: none;
    height: auto;
    width: 100%;
    max-width: 100vw;
    padding-bottom: 10px;
  }
  .nav-content {
    padding-top: 0px;
    padding-left: 0px;
  }

  .nav-footer {
    padding-left: 0px;
  }

  .content {
    margin: 0px 10px;
    padding: 0px 10px 0px 0px;
  }
}