ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    background-color: #333333;
}


.active a {
    padding-bottom: 9px;
    /* margin-bottom: 0px; */
    border-bottom: 3px solid rgb(90, 170, 255);
}


textarea:focus, input:focus {
    outline-style: solid
}

li {
    float: left;
    padding-left: 0px;
}

li:last-child {
    float: right;
    color: aqua;
}

li a {
    display: block;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: 12px min(20px, 1.3vw);
    width: 80px;
    height: 20px;
    text-decoration: none;
    transition: background-color 0.8s;
    /* padding-bottom: 12px; */
}


@media (min-width: 800px) {
    li a:hover {
        background-color: #6e7977;
    }
}